import Auth from "./auth/reducer";
import User from "./user/reducer";
import Clients from "./clients/reducer";
import Companies from "./companies/reducer";
import Vendors from "./vendors/reducer";
import Documents from "./documents/reducer";
import Invoices from "./invoices/reducer";
import Accounting from "./accounting/reducer";
import Reports from "./reports/reducer";
import Statements from "./statements/reducer";

const Reducers = {
  Auth,
  User,
  Clients,
  Companies,
  Vendors,
  Documents,
  Invoices,
  Accounting,
  Reports,
  Statements,
};

export default Reducers;
