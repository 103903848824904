import React, { Component } from 'react'
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { momentDateStrToLocalFormat } from "../../helpers/utils"
import { NavLink } from "react-router-dom"
import documentsActions from "../../redux/documents/actions";
import Pagination from "../../components/Pagination";

const {
  documentsGetAllForClient,
} = documentsActions;

class UploadedDocuments extends Component {
  state = {
  }

  componentDidMount() {
    const { userData } = this.props;
    this.props.documentsGetAllForClient({
      client_id: userData?.active_client?.id,
      type: "invoice",
    });
  }
  render() {
    const { userData, documents, documents_working } = this.props;

    return (
      <div>
        <h1 className="text-xl font-semibold leading-none mb-5">Uploaded Documents</h1>
        {documents_working ?
        <DocumentLoading />
        :
        documents?.data?.length > 0 ?
        <>
          <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4">
            <table className="w-full text-[15px] table-fixed">
              <thead>
                <tr>
                  <th className="border-b-2 border-slate-200">Description</th>
                  <th className="border-b-2 border-slate-200">Upload Date</th>
                  <th className="border-b-2 border-slate-200">Actions</th>
                </tr>
              </thead>
              <tbody>
                {documents?.data?.map(doc => (
                <tr key={`document-${doc.id}`}>
                  <td className="min-w-24 border-t py-2 border-slate-200">{doc.description}</td>
                  <td className="w-24 border-t py-2 border-slate-200">{momentDateStrToLocalFormat(doc.created_at)}</td>
                  <td className="min-w-24 border-t py-2 border-slate-200">
                    <NavLink to={`/app/client/${userData?.active_client?.id}/invoices/enter/document/${doc.id}`} className="btn-primary btn-sm no-underline">Enter Invoice</NavLink>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex flex-col items-end">
            <Pagination
              data={documents}
              callback={this.props.documentsGetAllForClient}
              callback_params={{client_id: userData?.active_client?.id}}
            />
          </div>
        </>
        :
        <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4">
          <p className="text-slate-500 text-[15px]">No invoice document uploaded.</p>
        </div>
        }
      </div>
    )
  }
}

const DocumentLoading = () => (
  <div className="bg-slate-100/75 rounded-lg mb-5 px-3 py-5 animate-pulse">
    <div className="grid grid-cols-3 mb-5 gap-10 content-evenly">
      <div className="h-2 bg-slate-300 rounded" />
      <div className="h-2 bg-slate-300 rounded" />
      <div className="h-2 bg-slate-300 rounded" />
    </div>
    {Array.from(Array(10)).map((item,idx) =>(
    <div key={`skeletion-${idx}`} className="grid grid-cols-8 gap-5 mb-5 h-8 bg-slate-300 rounded"></div>
    ))}
  </div>
)

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      documents: state.Documents.get("documents"),
      documents_working: state.Documents.get("documents_working"),
    }),
    {
      documentsGetAllForClient,
    }
  )(UploadedDocuments)
);
