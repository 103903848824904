import { Button, DialogTitle, Field, Input, Label } from "@headlessui/react"
import React, { Component } from 'react'
import DatePicker from "react-datepicker"
import SelectGLAccount from "../../components/SelectGLAccount"
import Modal from "../../components/Modal"
import CurrencyInput from "react-currency-input-field"

class ManualEntryModal extends Component {
  render() {
    const { open, onClose, onSave } = this.props

    return (
      <Modal open={open} onClose={onClose}>
        <DialogTitle className="font-semibold text-lg mb-4">Create New Entry</DialogTitle>
        <div className="min-w-96">
          <Field className="field">
            <Label>Amount</Label>
            <CurrencyInput
              prefix="$"
            />
          </Field>
          <Field className="field mt-4">
            <Label>Date</Label>
            <DatePicker />
          </Field>
          <Field className="field mt-4">
            <Label>Description</Label>
            <Input type="text" />
          </Field>
          <Field className="field mt-4">
            <Label>GL Account</Label>
            <SelectGLAccount />
          </Field>
          <div className="mt-8 flex justify-between gap-4">
            <Button className="btn-tertiary" onClick={onClose}>Cancel</Button>
            <Button className="btn-primary" onClick={onSave}>Save</Button>
          </div>
        </div>
      </Modal>
    )
  }
}

export default ManualEntryModal