import { Map } from "immutable";

const initState = new Map({
  users: [],
  users_working: false,
  cards: [],
  cards_working: false,
});

export default function companiesReducer(state = initState, action) {
  switch (action.type) {

    case "COMPANIES_CREATE_CARD":
      return state
        .set("cards_working", true)
    case "COMPANIES_CREATE_CARD_SUCCESS":
      return state
        .set("cards", [...state.get("cards"), action.data.data])
        .set("cards_working", false)
    case "COMPANIES_CREATE_CARD_FAILED":
      return state
        .set("cards_working", false)

    case "COMPANIES_GET_CARDS":
      return state
        .set("cards", [])
        .set("cards_working", true)
    case "COMPANIES_GET_CARDS_SUCCESS":
      return state
        .set("cards", [...action.data.data])
        .set("cards_working", false)
    case "COMPANIES_GET_CARDS_FAILED":
      return state
        .set("cards", [])
        .set("cards_working", false)

    case "COMPANIES_GET_USERS":
      return state
        .set("users", [])
        .set("users_working", true)
    case "COMPANIES_GET_USERS_SUCCESS":
      return state
        .set("users", [...action.data.data])
        .set("users_working", false)
    case "COMPANIES_GET_USERS_FAILED":
      return state
        .set("users", [])
        .set("users_working", false)

		default:
			return state;
  }
}
