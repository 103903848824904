import React, { Component } from 'react'
import Select from 'react-select'
import { Button, Field, Input, Label, Textarea } from "@headlessui/react"
import PDFViewer from "./PDFViewer"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { ReactComponent as IconFile } from '../assets/icons/icon-file.svg'

class CategorizeDocuments extends Component {
  state = {
    documents: [
      {id: 1, url_file: '/invoice_example.pdf', document_type: null, description: ""},
      {id: 2, url_file: '/invoice_example.pdf', document_type: null, description: ""}
    ],
    active_doc_idx: 0
  }

  prev = () => {
    const active_doc_idx = this.state.active_doc_idx
    const currentIdx = active_doc_idx - 1
    this.setState({ active_doc_idx: currentIdx < 0 ? 0 : currentIdx })
  }

  next = () => {
    const active_doc_idx = this.state.active_doc_idx
    const maxIdx = this.state.documents.length - 1
    const currentIdx = active_doc_idx + 1
    this.setState({ active_doc_idx: currentIdx > maxIdx ? maxIdx : currentIdx })
  }

  updateData = (idx, key, value) => {
    let documents = this.state.documents
    documents[idx][key] = value
    this.setState({ documents })
  }

  checkIfDataReady = () => {
    let is_ready = true
    const documents = this.state.documents
    documents.forEach(item => {
      if(item.document_type === null) {
        is_ready = false
        return
      }
    })
    return is_ready
  }

  render() {
    const { onCancel } = this.props
    const { documents, active_doc_idx } = this.state
    const active_doc = documents[active_doc_idx]
    const is_ready = this.checkIfDataReady()

    return (
      <div className="min-w-[1020px]">
        <h4 className="font-semibold text-lg mb-5">Categorize Documents</h4>
        <div className="flex justify-between items-center mt-4 text-sm mb-4">
          <div className="flex gap-2">
            <Button
              className="btn-tertiary btn-sm"
              disabled={active_doc_idx === 0}
              onClick={this.prev}
            >&lt; Prev.</Button>
            <Button
              className="btn-tertiary btn-sm"
              disabled={active_doc_idx === (documents.length - 1) }
              onClick={this.next}
            >Next &gt;</Button>
            <div className="ml-32 text-slate-500">
              <IconFile className={`inline size-6 ${!is_ready ? 'stroke-red-700' : 'stroke-slate-500'}`} />({documents.length})
            </div>
          </div>
          <span>Document {active_doc_idx+1} of {documents.length}</span>
        </div>
        <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4 flex gap-4">
          <div className="basis-1/4">
            <Field className="field">
              <Label>Client</Label>
              <Input type="text" disabled defaultValue={this.props.userData?.active_client?.name} />
            </Field>
            <Field className="field mt-4">
              <Label>Document Type</Label>
              <Select
                options={[
                  {value: 'invoice', label: 'Invoice'},
                  {value: 'credit_card', label: 'Credit Card'},
                ]}
                value={active_doc.document_type}
                onChange={selected => this.updateData(active_doc_idx, 'document_type', selected) }
              />
            </Field>
            <Field className="field mt-4">
              <Label>Description</Label>
              <Textarea
                placeholder="(optional)"
                value={active_doc.description}
                onChange={e => this.updateData(active_doc_idx, 'description', e.target.value) }
              />
            </Field>
          </div>
          <div className="basis-3/4">
            <PDFViewer
              file_url={active_doc.url_file}
              height={600}
            />
          </div>
        </div>
        <div className="mt-4 flex justify-between">
          <Button className="btn-tertiary" onClick={onCancel}>Cancel</Button>
          <Button
            className="btn-primary"
            disabled={!is_ready}
          >Save All</Button>
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
    }),
    {
      
    }
  )(CategorizeDocuments)
)
