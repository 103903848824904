import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { connect } from "react-redux";
import { withRouter } from "react-router"
import statementsActions from "../../redux/statements/actions";
import moment from "moment-timezone";

const {
  statementsGetAll,
} = statementsActions;

class CreditCards extends Component {

  componentDidMount = () => {
    this.props.statementsGetAll({client_id: this.props.userData?.active_client?.id});
  }

  render() {
    const { userData, statements, statements_working } = this.props

    return (
      <div>
        <div className="flex justify-between items-center mb-5">
          <h1 className="text-xl font-semibold leading-none">Manage Credit Card Statements</h1>
          <div className="flex justify-end gap-4">
            <Link to={`/app/client/${userData?.active_client?.slug}/credit-cards/import`}>Import Statement</Link>
            <Link to={`/app/client/${userData?.active_client?.slug}/credit-cards/list`}>Credit Cards</Link>
          </div>
        </div>
        <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4">
        {statements?.length > 0 ?
          <table className="w-full text-[15px]">
            <thead className="border-b-2 border-slate-200">
              <tr>
                <th className="p-3">Credit Card</th>
                <th className="p-3">Statement Date</th>
                <th className="p-3">Import Date</th>
                <th className="p-3"></th>
              </tr>
            </thead>
            <tbody>
              {statements.map(item => (
              <tr key={`statement-${item.id}`} className="border-t border-slate-200">
                <td className="p-3">{`[${item.credit_card_data?.card_number}] ${item.credit_card_data?.name}`}</td>
                <td className="p-3">{moment(item.statement_date).format("LL")}</td>
                <td className="p-3">{moment(item.created_at).format("LL")}</td>
                <td className="p-3 text-right">
                  <Link
                    to={`/app/client/${userData?.active_client?.slug}/credit-cards/statements/${item.id}`}
                    className="btn-primary btn-sm no-underline"
                  >View Transactions</Link>
                </td>
              </tr>
              ))}
            </tbody>
          </table>
          :
          <p>Currently no credit card statements found.</p>
        }
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      statements: state.Statements.get("statements"),
      statements_working: state.Statements.get("statements_working"),
    }),
    {
      statementsGetAll,
    }
  )(CreditCards)
)
