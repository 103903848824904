import React, { useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import { ReactComponent as IconZoomPlus } from '../assets/icons/icon-zoom-plus.svg'
import { ReactComponent as IconZoomMinus } from '../assets/icons/icon-zoom-minus.svg'
import { useDraggable } from "react-use-draggable-scroll"


pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

const MAX_SCALE = 2
const SCALE_STEP = .25

export default function PDFViewer({ file_url, height }) {
  const [numPages, setNumPages] = useState()
  const [scale, setScale] = useState(1)
  const ref = useRef()
  const { events } = useDraggable(ref, {
    applyRubberBandEffect: true
  })

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  function scalePlus() {
    if(scale <= MAX_SCALE) {
      setScale(scale + SCALE_STEP)
    }
  }

  function scaleMinus() {
    if(scale > 1) {
      setScale(scale - SCALE_STEP)
    }
  }

  return (
    <div className="border rounded">
      <div className="flex gap-2 justify-center p-2 bg-slate-100">
        <span
          role="button"
          className={scale === 1 ? 'opacity-50 pointer-events-none' : ''}
          onClick={scaleMinus}
        >
          <IconZoomMinus className="size-5" />
        </span>
        <span
          role="button"
          className={scale === MAX_SCALE ? 'opacity-50 pointer-events-none' : ''}
          onClick={scalePlus}
        >
          <IconZoomPlus className="size-5" />
        </span>
      </div>
      <div
        className="overflow-auto max-w-xl h-[840px] min-w-[480px] 2xl:min-w-[700px] p-2 bg-slate-100"
        style={{ height: height ? height : 840}}
        ref={ref} {...events}
      >
        <div className={scale > 1 ? '!cursor-move' : '!cursor-auto'}>
          <Document file={file_url} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={scale}
              />
            ))}
          </Document>
        </div>
      </div>
    </div>
  )
}