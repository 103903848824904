import { Map } from "immutable";

const initState = new Map({
  statements: [],
  statements_working: false,
  statement: null
});

export default function statementsReducer(state = initState, action) {
  switch (action.type) {

    case "STATEMENT_ENTRY_ADD":
      return state
        .set("statements_working", true)
    case "STATEMENT_ENTRY_ADD_SUCCESS":
      var statement_entry_add = {...state.get("statement")}
      statement_entry_add.entries = statement_entry_add.entries.unshift({...action.data.data});
      return state
        .set("statement", {...statement_entry_add})
        .set("statements_working", false)
    case "STATEMENT_ENTRY_ADD_FAILED":
      return state
        .set("statements_working", false)

    case "STATEMENT_ENTRY_DELETE":
      return state
        .set("statements_working", true)
    case "STATEMENT_ENTRY_DELETE_SUCCESS":
      var statement_entry_delete = {...state.get("statement")}
      statement_entry_delete.entries = statement_entry_delete.entries.filter(item => item.id !== action?.data?.data?.id);
      return state
        .set("statement", {...statement_entry_delete})
        .set("statements_working", false)
    case "STATEMENT_ENTRY_DELETE_FAILED":
      return state
        .set("statements_working", false)

    case "STATEMENT_UPDATE":
      return state
        .set("statements_working", true)
    case "STATEMENT_UPDATE_SUCCESS":
      var statement_update = {...action.data.data};
      if(action.data.data["action"] === "entry"){
        statement_update = {...state.get("statement")}
        statement_update.entries = statement_update.entries.map(item => {
          if(item?.id === action?.data?.data.id){
            return action.data.data;
          }
          return item;
        })
      }
      return state
        .set("statement", {...statement_update})
        .set("statements_working", false)
    case "STATEMENT_UPDATE_FAILED":
      return state
        .set("statements_working", false)

    case "STATEMENT_GET":
      return state
        .set("statement", null)
        .set("statements_working", true)
    case "STATEMENT_GET_SUCCESS":
      return state
        .set("statement", {...action.data.data})
        .set("statements_working", false)
    case "STATEMENT_GET_FAILED":
      return state
        .set("statements", null)
        .set("statements_working", false)

    case "STATEMENTS_GET_ALL":
      return state
        .set("statements", [])
        .set("statements_working", true)
    case "STATEMENTS_GET_ALL_SUCCESS":
      return state
        .set("statements", [...action.data.data])
        .set("statements_working", false)
    case "STATEMENTS_GET_ALL_FAILED":
      return state
        .set("statements", [])
        .set("statements_working", false)

    case "STATEMENTS_ADD":
      return state
        .set("statements_working", true)
    case "STATEMENTS_ADD_SUCCESS":
      return state
        .set("statements", [...state.get("statements"), action.data.data])
        .set("statements_working", false)
    case "STATEMENTS_ADD_FAILED":
      return state
        .set("statements_working", false)

		default:
			return state;
  }
}
