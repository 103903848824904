const companiesActions = {
	COMPANIES_GET_USERS: 'COMPANIES_GET_USERS',
	COMPANIES_GET_CARDS: 'COMPANIES_GET_CARDS',
	COMPANIES_CREATE_CARD: 'COMPANIES_CREATE_CARD',

  companiesCreateCard: (data) => ({
    type: companiesActions.COMPANIES_CREATE_CARD,
    data
  }),

  companiesGetCards: (data) => ({
    type: companiesActions.COMPANIES_GET_CARDS,
    data
  }),

  companiesGetUsers: (data) => ({
    type: companiesActions.COMPANIES_GET_USERS,
    data
  }),
}

export default companiesActions;
