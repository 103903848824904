import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import {
  Button,
  Field,
  Input,
  Label,
  Textarea,
  //Transition
} from "@headlessui/react"
import { ReactComponent as IconIgnore } from '../../assets/icons/icon-close-circle.svg'
//import { ReactComponent as IconImage } from '../../assets/icons/icon-image.svg'
import SelectGLAccount from "../../components/SelectGLAccount"
import { digitСonversion } from "../../helpers/utils"
//import Select from 'react-select'
//import Pagination from "../../components/Pagination"
import DatePicker from "react-datepicker"
import SortableHead from "../../components/SortableHead"
import { Tooltip } from "react-tooltip"
//import PDFViewer from "../../components/PDFViewer"
import statementsActions from "../../redux/statements/actions";
import moment from "moment-timezone";
import { ReactComponent as IconDownload } from '../../assets/icons/icon-download.svg'
import documentsActions from "../../redux/documents/actions";
import { ReactComponent as IconEdit } from '../../assets/icons/icon-edit.svg'
import { ReactComponent as IconCheckCircle } from '../../assets/icons/icon-check-circle.svg'
import ManualEntryModal from "./ManualEntryModal"

const {
  documentsDocumentPreview,
} = documentsActions;

const {
  statementGet,
  statementUpdate,
  statementEntryDelete,
} = statementsActions;

class ViewCCStatement extends Component {
  state = {
    active_sort_field: 'amount',
    active_sort_direction: 'asc',
    showStatementDoc: false,
    journal_date: null,
    journal_description: "",
    descriptions: {},
    gl_change_id: null,
    search: "",
    showManualEntryModal: false
  }

  componentDidMount = () => {
    this.props.statementGet({
      client_id: this.props.userData?.active_client?.id,
      statement_id: this.props.match?.params?.id,
    })
  }

  setStateLocal = (key, value) => {
    this.setState({[key]: value})
  }

  update = (data) => {
    data["client_id"] = this.props.userData?.active_client?.id;
    data["statement_id"] = this.props.statement?.id;
    var additional_actions = null;
    if(data?.action === "entry" && data?.account){
      additional_actions = {
        success: [
          {
            function: this.setStateLocal,
            params: [
              "gl_change_id",
              null,
            ]
          }
        ]
      }
    }
    this.props.statementUpdate(data, additional_actions);
  }

  updateDescription = (key, value) => {
    var desc = {...this.state.descriptions}
    desc[key] = value;
    this.setState({descriptions: desc})
  }

  downloadStatement = () => {
    const { userData, statement } = this.props;
    this.props.documentsDocumentPreview(
      {
        client_id: userData?.active_client?.id,
        document: statement?.document,
      },
    )
  }

  componentDidUpdate = (pp) => {
    if(this.props.document_preview_url && pp.document_preview_url !== this.props.document_preview_url){
      const link = document.createElement('a');
      link.href = this.props.document_preview_url;
      link.download = "test.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  render() {
    const {
      userData,
      statement,
      statements_working,
      document_preview_url_working
    } = this.props
    const {
      active_sort_field,
      active_sort_direction,
      gl_change_id
    } = this.state

    const entries = statement?.entries.filter(item => item.description.includes(this.state.search))

    return (
      <div>
        <div className="flex justify-between items-center mb-5">
          <h1 className="text-xl font-semibold leading-none">{`Credit Card Statement - [${statement?.credit_card_data?.card_number}] ${statement?.credit_card_data?.name} - ${statement?.statement_date}`}</h1>
          <div className="flex justify-end gap-4">
            <Link to={`/app/client/${userData?.active_client?.slug}/credit-cards/statements`}>&larr; Back</Link>
          </div>
        </div>
        <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4">
          <div className="flex gap-8 items-start">
            <Field className="field">
              <Label>Journal Date</Label>
              <DatePicker
                selected={this.state.journal_date
                  ? this.state.journal_date
                  : statement?.journal_date
                }
                onChange={(e) => {
                  this.setState({journal_date: e});
                  this.update({
                    action: "statement",
                    journal_date: e.toISOString()
                  })
                }}
                disabled={statements_working}
              />
            </Field>
            <Field className="field">
              <Label>Journal Description</Label>
              <Textarea
                type="text"
                className="w-96"
                onChange={(e) => this.setState({journal_description: e.target.value})}
                onBlur={(e) => {
                  this.update({
                    action: "statement",
                    journal_description: this.state.journal_description,
                  })
                }}
                disabled={statements_working}
                value={this.state.journal_description
                  ? this.state.journal_description
                  : statement?.journal_description
                }
              />
            </Field>
            {/*
            <Field className="field">
              <Label>Document</Label>
              <div
                className="flex items-center gap-2 py-2"
                role="button"
                onClick={() => this.setState({ showStatementDoc: true })}
              >
                <IconImage className="size-4" />
                <span className="text-blue-700">View document</span>
              </div>
              <Transition
                show={showStatementDoc ? true : false}
                enter="transition-transform duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
              >
                <div className="fixed top-20 right-0 z-10 shadow-lg">
                  <span
                    className="absolute left-2 top-2 text-lg"
                    role="button"
                    onClick={() => this.setState({ showStatementDoc: false })}
                  >&times;</span>
                  <PDFViewer file_url={'/cc-statement-example.pdf'} />
                </div>
              </Transition>
            </Field>
            */}
            <Field className="field">
              <Label>Data Source</Label>
              <div
                className="flex items-center gap-2 py-2"
                role="button"
                onClick={() => this.downloadStatement()}
                disabled={document_preview_url_working}
              >
                <IconDownload className="size-4" />
                <span className="text-blue-700">Download file</span>
              </div>
            </Field>
          </div>
        </div>

        <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4">
          <div className="mb-4 flex justify-between">
            <Input
              type="text"
              placeholder="Search description"
              onChange={(e) => this.setState({search: e.target.value})}
            />
            <div>
              {/*
              <Button
                className="btn-secondary"
                onClick={() => this.setState({ showManualEntryModal: true })}
              >Create new entry</Button>
              {this.state.showManualEntryModal &&
              <ManualEntryModal
                open={true}
                onClose={() => this.setState({ showManualEntryModal: false })}
                onSave={() => {}}
              />
              }
              */}
            </div>
          </div>
          <table className="w-full text-[15px]">
            <thead className="border-b-2 border-slate-200">
              <tr>
                <th className="py-1 px-3">#</th>
                <SortableHead
                  title="Amount"
                  field="amount"
                  active_field={active_sort_field}
                  active_direction={active_sort_direction}
                  sort={() => this.sort('amount')}
                  className="py-1 px-3 whitespace-nowrap"
                />
                <SortableHead
                  title="Date"
                  field="date"
                  active_field={active_sort_field}
                  active_direction={active_sort_direction}
                  sort={() => this.sort('date')}
                  className="py-1 px-3"
                />
                <SortableHead
                  title="Description"
                  field="description"
                  active_field={active_sort_field}
                  active_direction={active_sort_direction}
                  sort={() => this.sort('description')}
                  className="py-1 px-3"
                />
                <SortableHead
                  title="GL Account"
                  field="gl_account"
                  active_field={active_sort_field}
                  active_direction={active_sort_direction}
                  sort={() => this.sort('gl_account')}
                  className="py-1 px-3"
                />
                {/*
                <th className="py-1 px-3">Division</th>
                */}
                <th className="py-1 px-3"></th>
              </tr>
            </thead>
            <tbody>
              {entries?.map((item, idx) => (
              <tr key={`transaction-${idx}`} className={`border-t border-slate-200 ${item.excluded ? 'opacity-50' : ''}`}>
                <td className="py-1 px-3 max-w-2">{idx + 1}</td>
                <td className="py-1 px-3 text-right w-24">{digitСonversion(item?.amount, 'currency', "USD")}</td>
                <td className="py-1 px-3 whitespace-nowrap">{moment(item?.entry_date).format("LL")}</td>
                <td className="py-1 px-3">
                  <div className="relative">
                    <Input
                      className="w-72"
                      type="text"
                      defaultValue={this.state.descriptions[item.id]
                        ? this.state.descriptions[item.id]
                        : item.description
                      }
                      onChange={(e) => this.updateDescription(item.id, e.target.value)}
                      disabled={statements_working || item.excluded}
                    />
                    {(this.state.descriptions[item.id]
                      && this.state.descriptions[item.id] !== item.description) &&
                    <Button
                      className="btn-tertiary btn-sm absolute top-1.5 right-3"
                      onClick={() => this.update({
                        action: "entry",
                        entry_id: item.id,
                        description: this.state.descriptions[item.id],
                      })}
                      disabled={statements_working}
                    >Save</Button>
                    }
                  </div>
                </td>
                <td className="py-1 px-3">
                  <div className="w-96">
                    {(gl_change_id === item.id || !item.account) ?
                    <div className="flex items-center gap-3">
                      <SelectGLAccount
                        containerClassNames="w-96"
                        onChange={(value) => this.update({
                          action: "entry",
                          entry_id: item.id,
                          account: value.id,
                        })}
                        value={item.account}
                        disabled={statements_working || item.excluded}
                      />
                      <span role="button" onClick={() => this.setState({ gl_change_id: null })}>&times;</span>
                    </div>
                    :
                    <div className="flex items-center gap-2">
                      <span className="text-slate-500">
                        {item.account_data?.account_number
                          ? `${item.account_data?.account_number} - ${item.account_data?.title}`
                          : 'Undefined'
                        }
                      </span>
                      {!item.excluded &&
                      <IconEdit
                        className="size-4"
                        role="button"
                        onClick={() => this.setState({ gl_change_id: item.id })}
                      />
                      }
                    </div>
                    }
                  </div>
                </td>
                {/*
                <td className="py-1 px-3 w-60">
                  <Select />
                </td>
                */}
                <td>
                  {item.excluded ?
                  <>
                    <IconCheckCircle
                      data-tooltip-id={`t-include-${idx}`}
                      className="size-4 text-blue-700"
                      role="button"
                      onClick={(value) => this.update({
                        action: "entry",
                        entry_id: item.id,
                        excluded: false,
                      })}
                    />
                    <Tooltip id={`t-include-${idx}`} content="Include" />
                  </>
                  :
                  <>
                    <IconIgnore
                      data-tooltip-id={`t-ignore-${idx}`}
                      className="size-4"
                      role="button"
                      onClick={(value) => this.update({
                        action: "entry",
                        entry_id: item.id,
                        excluded: true,
                      })}
                    />
                    <Tooltip id={`t-ignore-${idx}`} content="Ignore" />
                  </>
                  }
                </td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between">
          <Button className="btn-primary">Submit</Button>
          {/*
          <Pagination
            data={{page: 1, pages: 3}}
            //callback={}
            //callback_params={{client_id: userData?.active_client?.id}}
          />
          */}
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      statement: state.Statements.get("statement"),
      statements_working: state.Statements.get("statements_working"),
      document_preview_url: state.Documents.get("document_preview_url"),
      document_preview_url_working: state.Documents.get("document_preview_url_working"),
    }),
    {
      statementGet,
      statementUpdate,
      documentsDocumentPreview,
      statementEntryDelete,
    }
  )(ViewCCStatement)
)
