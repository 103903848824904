const documentsActions = {
	DOCUMENTS_GET_ALL_FOR_CLIENT: 'DOCUMENTS_GET_ALL_FOR_CLIENT',
	DOCUMENTS_ADD_DOCUMENT: 'DOCUMENTS_ADD_DOCUMENT',
	DOCUMENTS_DOCUMENT_PREVIEW: 'DOCUMENTS_DOCUMENT_PREVIEW',

  documentsDocumentPreview: (data, additional_actions) => ({
    type: documentsActions.DOCUMENTS_DOCUMENT_PREVIEW,
    data,
    additional_actions,
  }),

  documentsAddDocument: (data) => ({
    type: documentsActions.DOCUMENTS_ADD_DOCUMENT,
    data
  }),

  documentsGetAllForClient: (data) => ({
    type: documentsActions.DOCUMENTS_GET_ALL_FOR_CLIENT,
    data
  }),
}

export default documentsActions;
