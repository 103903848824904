import { all, fork } from "redux-saga/effects";
//import { push } from "react-router-redux";
import { apiCalls } from "../../helpers/api/utils";
import { apiEndpoints } from "../../config/api";
import actions from "./actions";
import { commonSaga, default_config } from "../../helpers/redux/utils";

export function* companiesGetUsers() {
  yield commonSaga(
    actions.COMPANIES_GET_USERS,
    apiCalls.get,
    apiEndpoints.companies.users,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* companiesGetCards() {
  yield commonSaga(
    actions.COMPANIES_GET_CARDS,
    apiCalls.get,
    apiEndpoints.companies.cards,
    {
      ...default_config,
      ...{
        success_show: false,
      }
    }
  );
}

export function* companiesCreateCard() {
  yield commonSaga(
    actions.COMPANIES_CREATE_CARD,
    apiCalls.post,
    apiEndpoints.companies.cards,
    {
      ...default_config,
      ...{
        success_show: true,
      }
    }
  );
}

export default function* companiesSaga() {
	yield all([
		fork(companiesGetUsers),
		fork(companiesGetCards),
		fork(companiesCreateCard),
	]);
}
