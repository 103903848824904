import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { connect } from "react-redux";
import { withRouter } from "react-router"
import { Button, Field, Input, Label } from "@headlessui/react";
import companiesActions from "../../redux/companies/actions";

const {
  companiesGetCards,
  companiesCreateCard,
} = companiesActions;

class ManageCreditCards extends Component {
  state = {
    mode: 'list',
    name: "",
    card: "",
  }

  componentDidMount = () => {
    this.props.companiesGetCards();
  }

  add = () => {
    this.props.companiesCreateCard({
      name: this.state.name,
      card_number: this.state.card,
    })
    this.setState({
      card: "",
      name: "",
      mode: 'list',
    })
  }

  render() {
    const { userData, cards, cards_working } = this.props

    return (
      <div>
        {this.state.mode === 'list' &&
        <>
        <div className="flex justify-between items-center mb-5">
          <h1 className="text-xl font-semibold leading-none">Manage Credit Cards</h1>
          <div className="flex justify-end gap-4">
            <Link to={`/app/client/${userData?.active_client?.slug}/credit-cards/statements`}>&larr; Back</Link>
          </div>
        </div>
        <div className="flex flex-wrap gap-8">
          {cards?.map(item => (
            <div className="w-60 p-6 border rounded-lg shadow flex items-center">
              <div>
                <h4>{item.brand} {item.name}</h4>
                <div></div>
                <div>Number: {item.card_number}</div>
                <div
                  className="text-red-700 mt-4"
                  role="button"
                  onClick={() => {}}
                >Remove</div>
              </div>
            </div>
          ))}
          <div
            className="w-60 p-6 border rounded-lg shadow flex items-center justify-center hover:shadow-none"
            role="button"
            onClick={() => this.setState({ mode: 'add' })}
          >
            <span className="text-blue-700">+ Add Credit Card</span>
          </div>
        </div>
        </>
        }
        {this.state.mode === 'add' &&
        <>
        <div className="flex justify-between items-center mb-5">
          <h1 className="text-xl font-semibold leading-none">Add Credit Card</h1>
          <div className="flex justify-end gap-4">
            <span role="button" className="link" onClick={() => this.setState({ mode: 'list' })}>&larr; Back</span>
          </div>
        </div>
        <div className="flex">
          <div className="basis-1/3">
            <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4">
              <Field className="field">
                <Label>Card Name</Label>
                <Input
                  type="text"
                  onChange={(e) => this.setState({name: e.target.value})}
                  disable={cards_working}
                />
              </Field>
              <Field className="field mt-4">
                <Label>Card Number (last 4)</Label>
                <Input
                  type="text"
                  onChange={e => this.setState({
                    card: e.target.value.length > 4
                      ? this.state.card
                      : e.target.value
                  })}
                  disable={cards_working}
                  value={this.state.card}
                />
              </Field>
              <Button
                className="btn-primary mt-4"
                onClick={() => this.add()}
                disabled={cards_working || this.state.card.length < 4}
              >Save Card</Button>
            </div>
          </div>
        </div>
        </>
        }
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      cards: state.Companies.get("cards"),
      cards_working: state.Companies.get("cards_working"),
    }),
    {
      companiesGetCards,
      companiesCreateCard,
    }
  )(ManageCreditCards)
)
