import { Button, Field, Label } from "@headlessui/react"
import { connect } from "react-redux";
import React, { Component } from 'react'
import DatePicker from "react-datepicker"
import { Link } from "react-router-dom"
import Select from 'react-select'
import UploadFile from "./UploadFile"
import Spinner from "../../components/Spinner"
import { withRouter } from "react-router"
import companiesActions from "../../redux/companies/actions";
import statementsActions from "../../redux/statements/actions";

const {
  statementsAdd,
} = statementsActions;

const {
  companiesGetCards,
} = companiesActions;

class ImportCCStatement extends Component {
  state = {
    import_working: false,
    card: null,
    statement_date: null,
    selectedFileData: null,
  }

  import = () => {
    const { selectedFileData, card, statement_date } = this.state;
    const { userData } = this.props;

    var postData = new FormData();
    postData.append('document', selectedFileData);
    postData.append('document_description', `Credit card statement [${card.name}]`);
    postData.append('client_id', userData?.active_client.id);
    postData.append('document_type', "cc_statement");
    postData.append('credit_card', card?.value);
    postData.append('statement_date', statement_date.toISOString());

    this.props.statementsAdd(postData);
  }

  setLocal = (key, value) => {
    this.setState({[key]: value});
  }

  componentDidMount = () => {
    this.props.companiesGetCards();
  }

  render() {
    const { userData, cards, cards_working } = this.props
    console.log("aaaaaaaaaaaaaa", this.state.card)

    return (
      <div>
        <div className="flex justify-between items-center mb-5">
          <h1 className="text-xl font-semibold leading-none">Import Credit Card Statements</h1>
          <div className="flex justify-end gap-4">
            <Link to={`/app/client/${userData?.active_client?.slug}/credit-cards/statements`}>&larr; Back</Link>
          </div>
        </div>
        <div className="bg-slate-100/75 rounded-lg mb-5 text-slate-600 p-4">
          <div className="grid grid-cols-3 gap-8">
            <div>
              <Field className="field">
                <Label>Select Credit Card</Label>
                <Select
                  options={cards?.map(item => {
                    return {
                      value: item.id,
                      label: `[${item.card_number}] ${item.name}`
                    }
                  })}
                  value={this.state.card}
                  onChange={(e) => this.setState({ card: e })}
                  disabled={cards_working}
                />
              </Field>
              <Field className="field mt-4">
                <Label>Statement Date</Label>
                <DatePicker
                  className="w-full"
                  onChange={(e) => this.setState({ statement_date: e })}
                  disabled={cards_working}
                  selected={this.state.statement_date}
                />
              </Field>
            </div>
            <div>
              <Field className="field">
                <Label>Upload Statement Data</Label>
                <UploadFile
                  placeholderText="Drag and drop statement data (.ofx, .qfx, .csv)"
                  maxFileSize={5}
                  accept={
                    { 'application/x-ofx': ['.ofx'],
                      'application/vnd.intu.qfx' : ['.qfx'],
                      'text/csv': ['.csv']
                     }
                  }
                  onSelectFile={(e) => this.setLocal("selectedFileData", e)}
                />
              </Field>
            </div>
            <div>
              <Field className="field">
                <Label>Upload Statement Document</Label>
                <UploadFile
                  placeholderText="Drag and drop statement document (.pdf)"
                  maxFileSize={5}
                  accept={
                    { 'application/pdf': ['.pdf'] }
                  }
                  onUpload={() => console.log('process upload statement document')}
                />
              </Field>
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              className="btn-primary mt-4"
              disabled={cards_working
                || !this.state.card
                || !this.state.statement_date
              }
              onClick={this.import}
            >
              <span className="flex gap-1 justify-center items-center">
                {this.state.import_working && <Spinner />}
                <span>Import</span>
              </span>
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      userData: state.User.get("userData"),
      cards: state.Companies.get("cards"),
      cards_working: state.Companies.get("cards_working"),
      statements_working: state.Statements.get("statements_working"),
    }),
    {
      companiesGetCards,
      statementsAdd,
    }
  )(ImportCCStatement)
)
