import { commonAction } from "../../helpers/redux/utils";

const statementsActions = {
	STATEMENTS_ADD: 'STATEMENTS_ADD',
	STATEMENTS_GET_ALL: 'STATEMENTS_GET_ALL',
	STATEMENT_GET: 'STATEMENT_GET',
	STATEMENT_UPDATE: 'STATEMENT_UPDATE',
	STATEMENT_ENTRY_DELETE: 'STATEMENT_ENTRY_DELETE',
	STATEMENT_ENTRY_ADD: 'STATEMENT_ENTRY_ADD',

  statementEntryAdd: commonAction("STATEMENT_ENTRY_ADD"),
  statementEntryDelete: commonAction("STATEMENT_ENTRY_DELETE"),
  statementUpdate: commonAction("STATEMENT_UPDATE"),
  statementGet: commonAction("STATEMENT_GET"),
  statementsGetAll: commonAction("STATEMENTS_GET_ALL"),
  statementsAdd: commonAction("STATEMENTS_ADD"),
}

export default statementsActions;
