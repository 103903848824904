import { Transition } from "@headlessui/react"
import React, { Component, useCallback, useState } from 'react'
import { useDropzone } from "react-dropzone"

function FileUpload({ maxFileSize, accept, placeholderText, setFile }) {
  const [fileName, setFileName] = useState()
  const [errors, setErrors] = useState([])

  const onDrop = useCallback((acceptedFiles, rejectedFiles)  => {
    setErrors([])
    if(acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      setFileName(file.name)
      setFile(file)
    }

    if(rejectedFiles.length > 0) {
      const file = rejectedFiles[0]
      setErrors(file.errors)
    }
  }, [setFile])

  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    accept: accept,
    maxSize: maxFileSize * 1048576,
    onDrop
  })

  return (
    <div
      {...getRootProps()}
      className="border bg-white rounded-md px-5 flex flex-col items-center justify-center text-center cursor-pointer min-h-[120px]"
    >
      <input {...getInputProps()} />
      <Transition
        show={!!fileName}
        enter="duration-500 ease-in"
        enterFrom="opacity-0"
        enterTo="opacity-100"
      >
        <div className="mb-1">
          <p className="font-semibold mt-1">{fileName}</p>
        </div>
      </Transition>
      {errors.length > 0 && errors.map(err => <p key={err.code} className="text-red-500">{err.message}</p>)}
      <div className="text-slate-500">
        <p>{placeholderText ? placeholderText : 'Drag and drop your file here.'}</p>
        <p>Max size: {maxFileSize} MB</p>
      </div>
    </div>
  )
}

class UploadFile extends Component {
  state = {
    selectedFile: null
  }

  render() {
    const { maxFileSize, accept, placeholderText, onSelectFile } = this.props;

    return (
      <FileUpload
        placeholderText={placeholderText}
        setFile={file => onSelectFile(file)}
        maxFileSize={maxFileSize}
        accept={accept}
      />
    )
  }
}

export default UploadFile;
